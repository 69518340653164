<template>
  <div v-if="paymentDetail !== null" class="mt-4">
    <div class="card top_primary_card bg-primary font-bold text-white p-2 rounded-xl  w-full">
      <div class="flex justify-center items-center relative">
        <h1 class="heading-2 text-center">Payment Detail<span v-if="paymentDetail.paymentNo !== ''">: #{{paymentDetail.paymentNo}}</span></h1>
        <div class="flex items-center gap-3 absolute right-1">
          <div class=" text-error heading-4 cursor-pointer bg-white px-3 py-1 rounded-xl" @click="removeConfirm = true">
            <i class="fa-solid fa-trash"></i>
          </div>
          <div class=" text-primary heading-4 cursor-pointer bg-white px-2.5 py-1 rounded-xl" @click="editPayment">
            <i class="fa-solid fa-pen-to-square"></i>
          </div>
          <!-- <span @click="removeConfirm = true" class="text-error cursor-pointer heading-4">
                <i class="fa-solid fa-trash"></i>
                </span>
          <span @click="editPayment" class="cursor-pointer text-white heading-4"><i class="fa-solid fa-pen-to-square"></i></span> -->
        </div>
      </div>
    </div>
     <div class="py-2">
      <div class="card bg-white rounded-xl p-4 my-2" v-if="paymentDetail.paymentNotes !== ''">
        <div class="text-gray3 font-bold">Payment Note </div>
        <div class="text-text1 heading-5">{{paymentDetail.paymentNotes}}</div>
      </div>
    </div>
    <div>
         <div class="lg:grid grid-cols-6 gap-4">
          <div class="col-span-3 xl:col-span-2 2xl:col-span-2 mb-4">
              <div class="card bg-white p-4 rounded-xl">
                <div class=" text-white text-center bg-success mb-4 p-4 rounded-xl">
                  <p class="">Amount Received</p>
                  <p class="heading-2 font-bold">{{paymentDetail.paymentAmount.toFixed(2) | amountOnlyNumber}}</p>

                </div>
                <div class="mb-4">
                  <p class="heading-6 text-gray4">Customer</p>
                  <!-- <span class="cursor-pointer text-primary heading-4" @click.stop="customerDetail()">{{paymentDetail.companyName}}</span> -->
                  <div class="flex justify-between">
                <div class="">
                <span class="cursor-pointer text-primary font-semibold" @click.stop="customerDetail()">{{paymentDetail.companyName}}</span>
                </div>
                <div v-if="paymentDetail.customerHotNotes !== ''" class=" text-right">
                  <span class="cursor-pointer text-primary heading-7" @click.stop="showHotNOtes = !showHotNOtes"> {{showHotNOtes ? 'Hide' : 'Show'}} hot notes</span>
                </div>
              </div>
              <div v-if="showHotNOtes" class="">
                  <span class="cursor-pointer text-secondary">
                    {{paymentDetail.customerHotNotes}}
                  </span>
                </div>
                </div>
                <div class="mb-4 flex justify-between">
                  <div>
                    <p class="heading-6 text-gray4">Payment Method</p>
                    <p class="text-text2 heading-4">{{paymentDetail.paymentMethodName}}</p>
                  </div>
                  <div>
                    <p class="heading-6 text-gray4 text-right">Payment Date</p>
                    <p class="text-text2 heading-4 text-right">{{paymentDetail.addedDate | timeView}}</p>
                  </div>
                </div>
                <div class=" flex justify-between">
                  <div>
                    <p class="heading-6 text-gray4">Transaction Id</p>
                    <p class="text-text2 heading-4" v-if="paymentDetail.transactionId !== ''">{{paymentDetail.transactionId}}</p>
                    <p class="text-text2 heading-4" v-else>-</p>
                  </div>
                  <div>
                    <p class="heading-6 text-gray4 text-right">Recorded By</p>
                    <p class="text-text2 heading-4 text-right">{{paymentDetail.createdByUserFirstName}} {{paymentDetail.createdByUserLastName}}</p>
                  </div>
                </div>
              </div>
          </div>
          <div class=" col-span-3 xl:col-span-4 2xl:col-span-4 mb-10"  v-if="isInvoicePermission">
             <div class="card bg-white rounded-xl p-4 mb-4">
                  <p class=" text-gray4 heading-4 font-bold mb-4">Invoices Linked:</p>
                  <div style="overflow-y: auto; white-space: nowrap;" class="">
                    <div v-if="paymentDetail.linkedInvoiceList.length > 0">
                        <div class="text-gray4  min-w-min border-b border-gray1 font-bold flex heading-7  px-3 py-3 items-center">
                            <div class="table_col w-32  flex items-center">
                            <span class="">{{titleObj[0].title}}</span>
                            </div>
                            <div class="table_col w-44  flex items-center">
                            <span class="">{{titleObj[1].title}}</span>
                            </div>
                            <div class="table_col w-44  flex items-center">
                            <span class="">{{titleObj[2].title}}</span>
                            </div>
                            <div class="table_col w-32  flex items-center">
                            <span class="">{{titleObj[3].title}}</span>
                            </div>
                            <div class="table_col w-32  flex items-center">
                            <span class="">{{titleObj[4].title}}</span>
                            </div>
                            <div class="table_col w-32  flex items-center">
                            <span class="">{{titleObj[5].title}}</span>
                            </div>
                        </div>
                            
                            <div v-for="(data, index) in paymentDetail.linkedInvoiceList" :key="index" @click="openInvoiceDetails(data.invoiceId)" class="min-w-min flex heading-5 border-b border-gray1  bg-white  text-sm px-3 py-3 items-center hover:bg-gray1 cursor-pointer">
                            <div class="table_col w-32 ">
                                <div class="">
                                <p class="  text-text2">#{{data.invoiceNumber}}</p>
                                </div>
                            </div>
                            <div class="table_col w-44 ">
                                <div class="flex items-center">
                                <span class="  text-gray4 heading-6">{{data.grandTotal | amountOnlyNumber}}</span>
                                </div>
                            </div>
                            <div class="table_col w-44 ">
                                <div class="flex items-center">
                                <span class="  text-text2 heading-6">{{data.recordedAmount | amountOnlyNumber}}</span>
                                </div>
                            </div>
                            <div class="table_col w-32">
                                <div class="flex items-center">
                                <span class="  text-gray4 heading-6">{{data.invoiceDate | formatForDatePicker}}</span>
                                </div>
                            </div>
                            <div class="table_col w-32 ">
                                <div class="flex items-center">
                                <span class="  text-gray4 heading-6">{{data.dueDate | formatForDatePicker}}</span>
                                </div>
                            </div>
                            <div class="table_col w-32 ">
                                <div class="flex items-center">
                                <span :style="`color: ${data.invoiceStatusColor};`" class=" text-gray4 heading-6">{{data.invoiceStatusName}}</span>
                                </div>
                            </div>
                            </div>
                    </div>
                    <div v-else>
                      <NoRecords :alertMessage="` No records found.`" />
                    </div>
                  </div>
                </div>
          </div>
          <div class=" col-span-3 xl:col-span-4 2xl:col-span-4 mb-10"  v-else>
            <div class="card bg-white rounded-xl p-4 mb-4">
              <p class="heading-6 text-gray4">You Dont have permission to show Linked invoice list</p>
            </div>
          </div>
         </div>
    </div>
    <ConfirmBox v-if="removeConfirm" :message="'Once deleted cannot undone.'" :title="'Are you sure?'" />
  </div>
</template>
<script>
import {FilterPermissions} from '@/utils/Permissions.js'
import MyJobApp from "@/api/MyJobApp.js";
import NoRecords from '@/View/components/noRecords.vue'
import ConfirmBox from '@/View/components/ConfirmBox.vue'
export default {
  name: "customer",
  components: {
    NoRecords,
    ConfirmBox,
  },
  data() {
    return {
      isCustomerPermission: FilterPermissions('customer'),
      isInvoicePermission: FilterPermissions('invoice'),
      showHotNOtes: false,
      removeConfirm: false,
        paymentDetail: null,
        titleObj: [
          {title: 'Invoice Number', icon: '', sortName: ''},
          {title: 'Invoice Total', icon: '', sortName: ''},
          {title: 'Amount', icon: '', sortName: ''},
          {title: 'Date', icon: '', sortName: ''},
          {title: 'Due Date', icon: '', sortName: ''},
          {title: 'Status', icon: '', sortName: ''},
        ],
    };
  },
  created() {},
  mounted() {
    this.getPaymentDetails()
     this.$root.$on('confirmBoxHandler', (response) => {
        if (response) {
          this.deletesPayment()
        }
        document.body.style = 'overflow: visible;'
        this.removeConfirm = false
    })
  },
  methods: {
    customerDetail () {
      if (this.isCustomerPermission) {
        this.$router.push({name: 'customerDetails', params: {customerId: this.paymentDetail.customerId}})
      } else {
        this.$store.dispatch('ShowSubPopup', {status: true, title: `You don't have permission to open Customer Detail`})
      }
    },
    editPayment () {
      this.$router.push({name: 'recordPayment', query:{customerId: this.paymentDetail.customerId, paymentId: this.paymentDetail.paymentId}})
    },
     openInvoiceDetails (invoiceId) {
      this.$router.push({name: 'invoiceDetails', params: {invoiceId: invoiceId}})
    },
    getPaymentDetails() {
      this.$store.dispatch("SetLoader", {
        status: true,
        message: "Loading...",
      });
      MyJobApp.getPyamentDetail(
        parseInt(this.$route.params.paymentId),
        (response) => {
          this.paymentDetail = response.Data
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        },
        (err) => {
          this.$store.dispatch('SetAlert', {showAlert: true, message: err.message, color: 'error'})
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        }
      );
    },
    deletesPayment() {
      this.$store.dispatch("SetLoader", {  status: true,  message: "Loading...",});
      MyJobApp.deletePayment(
        parseInt(this.$route.params.paymentId),
        (response) => {
          if (this.$route.query.fromCall === 'customer') {
                this.$router.push({name: 'customerDetails', params: {customerId: this.paymentDetail.customerId}, query: {activeTab: 2}})
          } else {
            this.$router.go(-1)
          } 
         this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
          this.$store.dispatch("SetLoader", { status: false, message: "" });
          this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
        },
        (err) => {
          this.$store.dispatch('SetAlert', {showAlert: true, message: err.message, color: 'error'})
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        }
      );
    },
  },
  beforeDestroy() {
    this.$root.$off("confirmBoxHandler");
  },
};
</script>
<style scoped>
.table_col {
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.name_col {
    min-width: 200px;
    max-block-size: 200px;
}
.des_col {
    min-width: 250px;
    max-block-size: 250px;
}
.pric_col {
    min-width: 100px;
    max-block-size: 100px;
}
.qnty_col {
    min-width: 100px;
    max-block-size: 100px;
}
.amt_col {
    min-width: 100px;
    max-block-size: 100px;
}
</style>